<script setup>

import { defineEmits, defineProps, computed, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps(['modelValue', 'oldPicture'])

const oldPicture = computed(() => props.oldPicture ?? null)
const picture = ref(props.modelValue)
const uploadDisabled = ref(false)

const uploader = (event) => {
  uploadDisabled.value = true
  const reader = new FileReader()
  reader.readAsDataURL(event.files[event.files.length - 1])
  reader.onload = function () {
    picture.value = reader.result
    emit('update:modelValue', picture)
    uploadDisabled.value = false
  }
  reader.onerror = function () {
    uploadDisabled.value = false
  }
}

const uploadRemove = () => {
  if (oldPicture.value !== null) picture.value = oldPicture.value
  else picture.value = null

  emit('update:modelValue', picture)
}

</script>

<template>
  <div>
    <Image v-if="picture" :src="picture" preview class="mb-3" style="width: 100%"/>
    <FileUpload
      accept="image/*"
      :customUpload="true"
      :auto="true"
      :showUploadButton="false"
      :disabled="uploadDisabled"
      @uploader="uploader"
      @clear="uploadRemove"
    >
    </FileUpload>
  </div>
</template>
