<script setup>

import { onMounted, reactive, ref } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { useGroupChatMessageStore } from "@/store/group-chat/group-chat-message"
import { useToast } from "primevue/usetoast"

import ImageUpload from "../../../../modules/ImageUpload"

const route = useRoute()
const router = useRouter()
const toast = useToast()

const groupMessageStore = useGroupChatMessageStore()
const groupChatMessage = reactive({})

const creating = ref(false)

const loading = ref(false)

const optionTypes = [
  { label: "Text", value: 'text' },
  { label: "Image", value: 'image' }
]

const oldPicture = ref(null)

const save = () => {
  const action = creating.value
    ? groupMessageStore.store({ group_chat_id: route.params.groupChatId, message: groupChatMessage['message'], type: groupChatMessage['type'], picture: groupChatMessage['picture'] })
    : groupMessageStore.update(route.params.id, groupChatMessage)
  loading.value = true
  action.then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      loading.value = false
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})

    oldPicture.value = data.group_chat_message.url

    if (creating.value) {
      setTimeout(() => {
        Object.keys(data.group_chat_message).forEach((key) => {
          groupChatMessage[key] = data.group_chat_message[key]
        })
        
        router.replace({ name: 'official-chats.messages.edit', params: { id: data.group_chat_message.id } })
        creating.value = false
        loading.value = false
      }, 1000)

      return
    }

    loading.value = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

onMounted(() => {
  creating.value = (typeof route.params.id === 'undefined' && typeof groupChatMessage.id === 'undefined')

  loading.value = true
  if (typeof route.params.id !== 'undefined') {
    groupMessageStore.show(route.params.id)
      .then((response) => {
        Object.keys(response.data.group_chat_message).forEach((key) => {
          groupChatMessage[key] = response.data.group_chat_message[key]
        })

        groupChatMessage['picture'] = response.data.group_chat_message['url']
        oldPicture.value = response.data.group_chat_message['url']

        loading.value = false
      })
      .catch(() => loading.value = false)
  } else {
    groupChatMessage.type = 'text'

    loading.value = false
  }
})

</script>
<template>
  <div class="block-section">
    <div class="block-header">
        <span v-if="groupChatMessage.id" class="block-title">
            <div>Edit #{{ groupChatMessage.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>
    </div>

    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div>

            <label class="block text-900 font-medium mb-2" for="type">Type</label>
            <SelectButton v-model="groupChatMessage.type" id="type" class="w-full mb-3" :options="optionTypes" optionLabel="label" optionValue="value" />

            <div v-if="groupChatMessage.type === 'text'">
              <label class="block text-900 font-medium mb-2" for="message">Message</label>
              <Textarea v-model="groupChatMessage.message" id="message" rows="5" class="w-full mb-3" />
            </div>
            <div v-else>
              <label class="block text-900 font-medium mb-2" for="picture">Image</label>
              <ImageUpload v-if="creating || groupChatMessage.picture" v-model="groupChatMessage.picture" :oldPicture="oldPicture" id="picture" class="w-full mb-3" />
            </div>
            
            <div v-if="! creating">
              <label class="block text-900 font-medium mb-2" for="avatar_url">Avatar</label>
              <Avatar :image="groupChatMessage.avatar_url" id="avatar_url" size="xlarge" shape="circle" class="mb-3" />

              <label class="block text-900 font-medium mb-2" for="name">Name</label>
              <InputText v-model="groupChatMessage.name" id="name" type="text" class="w-full mb-3" readonly />
              
              <label class="block text-900 font-medium mb-2" for="loves">Loves</label>
              <InputText v-model="groupChatMessage.loves" id="loves" type="text" class="w-full mb-3" readonly />
              
              <label class="block text-900 font-medium mb-2" for="views">Views</label>
              <InputText v-model="groupChatMessage.views" id="views" type="text" class="w-full mb-3" readonly />
              
              <label class="block text-900 font-medium mb-2" for="created_at">Created</label>
              <div class="mb-3">{{ groupChatMessage.created_at ? new Date(groupChatMessage.created_at).toLocaleString() : '-' }}</div>

              <label class="block text-900 font-medium mb-2" for="updated_at">Updated</label>
              <div class="mb-3">{{ groupChatMessage.updated_at ? new Date(groupChatMessage.updated_at).toLocaleString() : '-' }}</div>
            
            </div>
            
          </div>

          <br/>

          <Button label="Save" class="w-full" :loading="loading" @click="save()" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.p-image img {
  width: 100%;
  border: 1px solid #dee2e6 !important;
}
.p-fileupload-buttonbar {
  border-bottom: 1px solid #dee2e6 !important;
  margin-bottom: 1rem;
}
.p-fileupload-content {
  display: none;
}
</style>
